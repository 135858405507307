import Hero from "./../components/hero/Hero";
import Content from "./../components/content/Content";
import Projects from "./../components/projects/Projects";
import Clients from "./../components/clients/Clients";
import Footer from "./../components/footer/Footer";

function HomePage() {
	return (
		<>
			<Hero title="Rafael Peralta Jr" role="Web Developer" />
			<Content title="About Me" copy="I'm a web developer based in New Jersey with a diverse range of experience. Throughout my career, I've worked on freelance projects, collaborated with agencies, and partnered with talented professionals to develop websites, landing pages, and interactive web applications for several Fortune 500 companies. My passion and dedication to my craft have driven me to continually enhance my skills and expand my expertise in web, app, and business development." />
			<Projects />
			<Clients title="I'm proud to have collaborated with some amazing companies" />
			<Footer title="Contact Me" />
		</>
	);
}

export default HomePage;